import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/pages/w4w.scss"

const W4W = () => {
  const image = name =>
    `https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/${name}`
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Learn about our Wisdom For Wisdom program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />

      <div className="w4w-23">
        <div className="w4w-23__container">
          <div className="w4w-23__hero">
            <img
              className="w4w-23__hero--logo"
              src={image("2022_W4W_Logo-01")}
              alt="wisdom for wisdom program logo"
            />
            <h1>
              HELPING
              <br />
              STUDENTS
              <br />
              SAVE MONEY
              <br />
              FOR COLLEGE
            </h1>

            <div>
              <img
                className="w4w-23__hero--desktop"
                src={image("2023-w4w-hero-image")}
                alt="wisdom for wisdom grid art"
              />

              <img
                className="w4w-23__hero--mobile"
                src={image("2023-hero-image-mobile")}
                alt="wisdom for wisdom grid art"
              />
            </div>
          </div>
        </div>

        <div className="w4w-23__section">
          <div className="w4w-23__container">
            <h2>Saving for College? We Want to Help!</h2>
            <p>
              Our 2024 Wisdom For Wisdom program seeks to help students save for
              college by providing free wisdom teeth removal procedures.
            </p>
            <p>
              This year, we are proud to announce Dylan Williams, David Varner,
              Maria Cossio Zermeno, and Emily Gesing as the recipients of our
              2024 Wisdom for Wisdom program.
            </p>
            <p>
              In their application, Dylan's family expressed gratitude for the
              opportunity to receive help through the Wisdom for Wisdom program,
              recognizing the financial burden that comes with both higher
              education and healthcare. Dylan, a student-athlete with a 4.0 GPA,
              is also preparing for his future as a veterinarian by attending
              Green River Community College in the Running Start program. His
              commitment to education, sports, and his part-time job reflects
              his dedication to achieving his dreams. This procedure would
              support Dylan’s overall health, allowing him to focus fully on his
              academic and athletic pursuits without the weight of dental
              expenses.{" "}
            </p>
            <p>
              David was advised to have his wisdom teeth removed two years ago,
              but the cost of the procedure has made it impossible for him to
              proceed. Now, as he prepares to attend college, receiving
              assistance through the Wisdom for Wisdom program would allow David
              to focus on his studies and reduce the financial burden of his
              college education. With fewer expenses related to his wisdom teeth
              removal, he will be able to dedicate his savings to his tuition,
              ensuring he can pursue his academic goals with confidence.
            </p>
            <p>
              Maria, a first-generation college student and low-income
              immigrant, has also faced significant financial challenges. For
              the past four years, Maria has been advised to have her wisdom
              teeth removed, but due to the high cost, she has been unable to
              afford the procedure. The pain caused by her impacted teeth has
              led to frequent headaches and discomfort, which has affected her
              daily life. By receiving support from the Wisdom for Wisdom
              program, Maria would be able to focus on her education, allowing
              her to fully embrace her college experience and work toward better
              grades and a brighter future.
            </p>
            <p>
              For Emily, the financial burden of attending college is already
              significant, and the cost of removing her wisdom teeth only adds
              to the challenge. Emily’s long-term goal is to attend physician
              assistant school and eventually open her own practice, but she
              knows this journey will require both time and financial resources.
              Receiving assistance through the Wisdom for Wisdom program would
              relieve one of the immediate financial obstacles she faces,
              allowing her to focus on her academic goals. This program would be
              a vital step toward helping Emily achieve her dreams of entering
              the medical field and giving back to her community.
            </p>

            <p>
              The Wisdom for Wisdom program empowers students like David, Dylan,
              Maria, and Emily to pursue their dreams, free from the worry of
              unexpected medical costs. We are honored to support them as they
              embark on this important chapter of their lives.
            </p>
            <p>
              We wish every student the best as they pursue their academic
              endeavors.
            </p>

            <p>
              To follow their stories and procedures, follow us on{" "}
              <a
                href="https://www.facebook.com/PNWFederalWay/"
                target="_blank"
                title="Follow us on Facebook">
                Facebook
              </a>
              .
            </p>

            <p>
              Learn more about{" "}
              <a
                href="/oral-surgery-procedures/wisdom-teeth-removal-auburn-wa/"
                title="Learn more about wisdom teeth removal">
                wisdom teeth removal
              </a>
              .
            </p>
          </div>
        </div>

        <div className="w4w-23__rounded-container">
          <div className="w4w-23__container">
            <div className="w4w-23__grid">
              <div>
                <h3>The Facts...</h3>
                <ul>
                  <li>
                    Wisdom teeth are the only teeth that don’t form before
                    birth.
                  </li>
                  <li>
                    9 out of 10 people will have at least one wisdom tooth that
                    is impacted.
                  </li>
                  <li>Wisdom teeth are also known as third molars.</li>
                  <li>
                    Wisdom teeth got their nickname because they often grow in
                    during the “age of wisdom” usually between the ages of 17
                    and 25.
                  </li>
                  <li>
                    Wisdom teeth growing in at the wrong angle may press against
                    other teeth, causing them to become misaligned.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default W4W
